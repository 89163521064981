import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { searchServiceOrders } from "../../API/ApiOrdenesServicio";

export const BuscadorDeOrdenesDeServicioHookComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [numeroContrato, setNumeroContrato] = React.useState(0);
  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        className="mt-3"
        options={finalData}
        getOptionLabel={(option) =>
          option.folio + "-" + option.nombreSolicitante + option.nombreFinado
        }
        fullWidth
        onChange={(event, newValue) => {
          let valores = newValue.folio.split("-", 5);
          props.setNumeroOrdenServicio(valores[0]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Ingresa Órden de Servicio"
            onChange={(e) => {
              if (e.target.value == "") {
                return;
              }

              searchServiceOrders(user, e.target.value).then((resultado) => {
                if (resultado.status == 200) {
                  setFinalData(resultado.data);
                } else {
                  setFinalData([]);
                }
              });
            }}
            onKeyPress={(e) => {
              if (e.target.value == "") {
                return;
              }

              if (e.key === "Enter") {
                let valores = e.target.value.split("-", 5);
                props.setNumeroOrdenServicio(valores[0]);
              }
            }}
          />
        )}
      />
      <br />
    </>
  );
};
