import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { newContract } from "../../API/ApiContratos";
import { crearEmpleado } from "../../API/ApiEmpleados";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getEmpleadosDosPuntoCeroAPI,
  postAsignarComisionVendedorIdContrato,
  postVendedorToContratoAPI,
} from "../../API/APiVendedores";
import { IoIosCalculator } from "react-icons/io";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { FaPeopleArrows } from "react-icons/fa";

import Select from "react-select";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { Backdrop } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  idempleado: yup.string("Ingresa").required("Requerido"),
  idcontrato: yup.string("Ingresa").required("Requerido"),
  generaComision: yup.string("Ingresa").required("Requerido"),
});

export const AddVendedorToContratoComponent = (props) => {
  const handleClose = () => {
    if (isDisabled == false) {
      props.setCrudAction({ type: null, data: null });
    } else {
      toast.success("Espera un momento, procesando información...");
    }
  };

  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);

  const [empleados, setEmpleados] = React.useState([]);

  const [confirmacion, setConfirmacion] = React.useState(false);
  const [confirmacionCalculo, setConfirmacionCalculo] = React.useState(false);

  const [etapa, setEtapa] = React.useState(
    props.crudAction.type == "calcularComisiones" ? 2 : 1
  );

  const formik = useFormik({
    initialValues: {
      idempleado: "",
      idcontrato: props.contrato,
      generaComision: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      postVendedorToContratoAPI(user, values).then((resultado) => {
        debugger;

        if (resultado.status == 200) {
          toast.success("Vendedor Agregado Correctamente");

          props.setContadorActualizar(
            props.contadorActualizar + 1
          );

          
          setIsDisabled(false);
          setEtapa(2);
        } else {
          toast.error(resultado.message);
          setIsDisabled(false);
        }
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  React.useEffect(() => {
    //Haciendo la llamada de los nuevos empleados

    getEmpleadosDosPuntoCeroAPI(user).then((resultado) => {
      if (resultado.status == 200) {
        const options = resultado.data.map((empleado) => ({
          value: empleado.id,
          label: `${empleado.nombre} ${empleado.apellidoPaterno} ${empleado.apellidoMaterno}`,
        }));
        setEmpleados(options);
      } else {
        toast.error("Ocurrió un error al mostrar los empleados.");
        setEmpleados([]);
      }
    });
  }, [props.contadorActualizar]);

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Asignar Vendedor a Contrato
          </Typography>
          {isDisabled == true ? (
            <>
              <Alert className="mb-3" severity="info">
                Procesando información...
              </Alert>
            </>
          ) : null}

          {etapa == 2 ? (
            <>
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3 text-success">
                    <IoIosCalculator /> Para terminar es necesario mandar llamar
                    la acción de cálculo de comisiones.
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3 fw-bold">
                    <div class="alert alert-success" role="alert">
                      Confirma que deseas hacer los cálculos de comisión :{" "}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-3 mt-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="confirmaComisionCalculo"
                        name="confirmaComisionCalculo"
                        checked={confirmacionCalculo}
                        onChange={(e) => {
                          debugger;
                         
                          setConfirmacionCalculo(e.target.checked);
                        }}
                        disabled={isDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="confirmaComisionCalculo"
                      >
                        Confirmo que el vendedor ha sido asignado a este
                        contrato y deseo generar los cálculos de comisión.
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-3">
                    {confirmacionCalculo == true ? (
                      <button
                        className="my-4 btn btn-success w-100 text-white"
                        type="submit"
                        disabled={isDisabled}
                        onClick={() => {
                          debugger;
                          setIsDisabled(true);
                          postAsignarComisionVendedorIdContrato(
                            user,
                            props.contrato
                          ).then((resultado) => {
                            debugger;
                            if (resultado.status == 200) {
                              toast.success(
                                "Comisiones calculadas correctamente"
                              );

                              props.setContadorActualizar(
                                props.contadorActualizar + 1
                              );
                              setIsDisabled(false);
                              props.setCrudAction({ type: null, data: null });
                            } else {
                              debugger;
                              toast.error(resultado?.response?.data?.message);
                              setIsDisabled(false);
                            }
                          });
                        }}
                      >
                        <FaPeopleArrows className=" me-1" /> Calcular Comisiones
                        para{" "}
                        {
                          empleados.find(
                            (empleado) =>
                              empleado.value == formik.values.idempleado
                          )?.label
                        }
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {etapa == 1 ? (
            <form onSubmit={formik.handleSubmit} className="">
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3">
                    Ingresa al vendedor de este contrato.
                  </div>
                </div>
              </div>
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3">
                    <Select
                      options={empleados}
                      name="idempleado"
                      id="idempleado"
                      onChange={(selectedOption) =>
                        formik.setFieldValue("idempleado", selectedOption.value)
                      }
                      isDisabled={isDisabled}
                      placeholder="Selecciona el vendedor"
                      label="Selecciona el vendedor"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="generaComision"
                        name="generaComision"
                        checked={formik.values.generaComision}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="generaComision"
                      >
                        Genera Comisión
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3 mt-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="confirmaComision"
                        name="confirmaComision"
                        checked={confirmacion}
                        onChange={(e) => {
                        
                          setConfirmacion(e.target.checked);
                        }}
                        disabled={isDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="confirmaComision"
                      >
                        Confirmo que el vendedor ha sido asignado a este
                        contrato.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {confirmacion == true ? (
                <button
                  className="my-4 btn btn-success w-100 text-white"
                  type="submit"
                  disabled={isDisabled}
                >
                  <FaPeopleArrows className=" me-1" /> Asignar Vendedor
                </button>
              ) : null}
            </form>
          ) : null}
        </Box>
      </Modal>
    </>
  );
};
