import React from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer, toast } from "react-toastify";
import { AppRouter } from "./Routers/AppRouter.js";
import { AuthContext } from "./auth/authContext";
import { authReducer } from "./auth/authReducer";
import { io } from "socket.io-client";
import "animate.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom";
import "./Styles/fonts.css";
import "react-toastify/dist/ReactToastify.css";
import { ConfigurationContext } from "./configuration/configurationContext";
import { configurationReducer } from "./configuration/configurationReducer";
import { ActualizadorContext } from "./actualizadorContext/actualizadorContext.js";

import { useCookies } from "react-cookie";

import Cookies from "js-cookie";
import { VendedoresContratoProvider } from "./contexts/VendedoresContratoContext.js";
import { LoadingProvider } from "./contexts/LoadingContext.js";

const init = () => {
  let hola = Cookies.get(process.env.REACT_APP_COOKIE_NAME);
  if (typeof hola == "undefined") {
    return { logged: false };
  } else {
    return JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_NAME));
  }
};

const initConfiguration = () => {
  return (
    JSON.parse(localStorage.getItem("configurationVitanova")) || {
      menuAbierto: true,
    }
  );
};

const contadorActualizadorRender = () => {
  return { contadorActualizar: 0 };
};

function App() {
  const [user, dispatch] = React.useReducer(authReducer, {}, init);

  const [actualizarVentanaContext, dispatchActualizarVentanaContext] =
    React.useState(contadorActualizadorRender);

  const [configuration, dispatchConfiguration] = React.useReducer(
    configurationReducer,
    {},
    initConfiguration
  );

  React.useEffect(() => {
    if (!configuration) return;
    localStorage.setItem(
      "configurationVitanova",
      JSON.stringify(configuration)
    );
  }, [configuration]);

  
  return (
    <React.Fragment>
      <HelmetProvider>
        <VendedoresContratoProvider>
          <LoadingProvider>
            <AuthContext.Provider value={{ user, dispatch }}>
              <ConfigurationContext.Provider
                value={{ configuration, dispatchConfiguration }}
              >
                <ActualizadorContext.Provider
                  value={{
                    actualizarVentanaContext,
                    dispatchActualizarVentanaContext,
                  }}
                >
                  <ToastContainer />
                  <AppRouter />
                </ActualizadorContext.Provider>
              </ConfigurationContext.Provider>
            </AuthContext.Provider>
          </LoadingProvider>
        </VendedoresContratoProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
