import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import {
  newContract,
  updateEtapaTres,
  updateEtapaUno,
} from "../../API/ApiContratos";
import { getServiciosContrato } from "../../API/ApiServicios";
import InfoIcon from "@mui/icons-material/Info";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import InputAdornment from "@mui/material/InputAdornment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import { NumericFormat } from "react-number-format";

const validationSchema = yup.object({
  idservicio: yup.string("Ingresa Servicio").required("Servicio requerido"),
  importe: yup.string("Ingresa importe").required("Importe requerido"),

  plazo: yup.string("Ingresa plazo").required("Plazo requerido"),
  fechaInicioPago: yup
    .string("Ingresa fecha inicio de pago")
    .required("Fecha requerida"),
  domicilioCobro: yup
    .string("Ingresa domicilio")
    .required("Domicilio requerido"),
  tipoPrecio: yup.string("Ingresa precio").required("Precio requerido"),
  diaDePago: yup
    .string("Ingresa día de pago")
    .required("Día de pago requerido"),
});

export const EditContratosEtapaTresComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [servicios, setServicios] = React.useState([]);
  const [servicioSeleccionadoTexto, setServicioSeleccionadoTexto] =
    React.useState("");
  const [precioSeleccionadoTexto, setPrecioSeleccionadoTexto] =
    React.useState("");

  React.useEffect(() => {
    getServiciosContrato(user).then((resultado) => {
      if (resultado.status == 200) {
        setServicios(resultado.data);
        setServicioSeleccionadoTexto(
          typeof props.finalData.etapaDos[0] === "undefined"
            ? ""
            : props.finalData.etapaDos[0].idservicio
        );
      } else {
        toast.error("No se han podido cargar los servicios.");
        setServicios([]);
      }
    });
  }, []);

  React.useEffect(() => {
    for (const servicio of servicios) {
      if (servicio.id == servicioSeleccionadoTexto) {
      }
    }
  }, [servicioSeleccionadoTexto]);

  const formik = useFormik({
    initialValues: {
      noContrato:
        (typeof props.finalData.etapaUno !== "undefined" &&
          props.finalData.etapaUno.length === 0) ||
        typeof props.finalData.etapaUno[0].noContrato === "undefined"
          ? ""
          : props.finalData.etapaUno[0].noContrato,
      idcontrato: props.contrato,
      idservicio:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].idservicio === "undefined"
          ? ""
          : props.finalData.etapaDos[0].idservicio,
      importe:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].importe === "undefined"
          ? ""
          : props.finalData.etapaDos[0].importe,

      plazo:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].plazo === "undefined"
          ? ""
          : props.finalData.etapaDos[0].plazo,
      fechaInicioPago:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].fechaInicioPago === "undefined"
          ? ""
          : props.finalData.etapaDos[0].fechaInicioPago,
      domicilioCobro:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].domicilioCobro === "undefined"
          ? ""
          : props.finalData.etapaDos[0].domicilioCobro,
      tipoPrecio:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].tipoPrecio === "undefined"
          ? ""
          : props.finalData.etapaDos[0].tipoPrecio,
      modoDePago:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].modoDePago === "undefined"
          ? "1"
          : props.finalData.etapaDos[0].modoDePago,
      tipoPrecio:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].tipoPrecio === "undefined"
          ? ""
          : props.finalData.etapaDos[0].tipoPrecio,
      diaDePago:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].diaDePago === "undefined"
          ? ""
          : props.finalData.etapaDos[0].diaDePago,
      formaPago:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].formaPago === "undefined"
          ? "1"
          : props.finalData.etapaDos[0].formaPago,
      serieDeCobranza:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].serieDeCobranza === "undefined"
          ? "A"
          : props.finalData.etapaDos[0].serieDeCobranza,
      precioContado:
        (typeof props.finalData.etapaDos !== "undefined" &&
          props.finalData.etapaDos.length === 0) ||
        typeof props.finalData.etapaDos[0].precioContado === "undefined"
          ? "0"
          : props.finalData.etapaDos[0].precioContado,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      /* if (
        props.finalData.restructura.totalPagosContratoAnterior[0]
          .totalPagosAnterior == null
      ) {
      } else {
        values.saldo =
          values.importe -
          parseFloat(
            props.finalData.restructura.totalPagosContratoAnterior[0]
              .totalPagosAnterior
          );
      }*/

      setIsDisabled(true);
      updateEtapaTres(user, values, props.contrato).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Datos Finanzas Actualizados Correctamente");
          window.location.reload();
        } else {
          toast.error(resultado.message);
        }
      });
      setIsDisabled(false);
    },
  });

  return (
    <div className="container-fluid p-2">
      <div className="row">
        <div className="col-12">
          {isDisabled == true ? (
            <>
              <Alert className="mb-3" severity="info">
                Procesando información...
              </Alert>
            </>
          ) : null}
          <form onSubmit={formik.handleSubmit} className="">
            <div className="container-fluid g-0">
              <div className="row row-cols-md-1">
                <div className="col-md-3 my-3">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Servicio
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idservicio}
                      onChange={(e) => {
                        setServicioSeleccionadoTexto(e.target.value);

                        formik.setFieldValue("idservicio", e.target.value);

                        formik.handleChange(e);
                      }}
                      disabled={isDisabled}
                      inputProps={{
                        name: "idservicio",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={""}>Seleccionar Servicio</option>
                      {servicios.map((servicio, index) => (
                        <option value={servicio.id} key={index}>
                          {servicio.nombre} - {servicio.descripcion} -{" "}
                          {servicio.precioContado}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <br />
                  <br />
                  <div className="card">
                    <div className="card-body text-justify">
                      {servicios.map((servicios2, index) =>
                        servicios2.id == servicioSeleccionadoTexto ? (
                          <React.Fragment key={index}>
                            <strong> {servicios2.nombre}</strong>
                            <br />
                            <small>{servicios2.descripcion}</small>
                            <hr />
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">
                                Seleccionar Precio
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={(e) => {
                                  var hola = e.target.value;
                                  setPrecioSeleccionadoTexto(hola);

                                  var divisiones = hola.split("$", 2);
                                  formik.setFieldValue(
                                    "tipoPrecio",
                                    divisiones[0].slice(0, -1)
                                  );
                                  formik.setFieldValue(
                                    "importe",
                                    divisiones[1].slice(0, -1)
                                  );
                                }}
                              >
                                <FormControlLabel
                                  disabled={
                                    typeof props.finalData.etapaDos[0] ===
                                    "undefined"
                                      ? false
                                      : false
                                  }
                                  value={
                                    "Precio Lista:$" + servicios2.precioLista
                                  }
                                  control={<Radio />}
                                  label={"Precio Lista"}
                                />

                                <FormControlLabel
                                  disabled={
                                    typeof props.finalData.etapaDos[0] ===
                                    "undefined"
                                      ? false
                                      : false
                                  }
                                  value={
                                    "Promoción:$" + servicios2.precioPromocion
                                  }
                                  control={<Radio />}
                                  label={"Promoción"}
                                />
                              </RadioGroup>
                            </FormControl>
                          </React.Fragment>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="container-fluid p-2">
                    <div className="row">
                      <div className="col-md-4">
                        <NumericFormat
                          value={formik.values.importe}
                          allowLeadingZeros
                          thousandSeparator=","
                          size="small"
                          fullWidth
                          id="importe"
                          name="importe"
                          className="mb-3"
                          label="Importe $"
                          prefix={"$"}
                          customInput={TextField}
                          variant="standard"
                          allowedDecimalSeparators={["%"]}
                          onValueChange={(values) => {
                            console.log(values);

                            formik.setFieldValue("importe", values.value);
                          }}
                        />

                        <TextField
                          size="small"
                          fullWidth
                          id="importe"
                          name="importe"
                          label="Importe $"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                          type={"number"}
                          className="d-none"
                          value={formik.values.importe}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={
                            formik.touched.importe &&
                            Boolean(formik.errors.importe)
                          }
                          helperText={
                            formik.touched.importe && formik.errors.importe
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <NumericFormat
                          value={formik.values.precioContado}
                          allowLeadingZeros
                          thousandSeparator=","
                          size="small"
                          fullWidth
                          id="precioContado"
                          name="precioContado"
                          className="mb-3"
                          label="Precio Contado $"
                          prefix={"$"}
                          customInput={TextField}
                          variant="standard"
                          allowedDecimalSeparators={["%"]}
                          onValueChange={(values) => {
                            console.log(values);

                            formik.setFieldValue("precioContado", values.value);
                          }}
                        />
                      </div>

                      <div className="col-md-4">
                        <TextField
                          size="small"
                          fullWidth
                          id="plazo"
                          name="plazo"
                          label="Plazo"
                          type={"number"}
                          className="mb-3"
                          value={formik.values.plazo}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={
                            formik.touched.plazo && Boolean(formik.errors.plazo)
                          }
                          helperText={
                            formik.touched.plazo && formik.errors.plazo
                          }
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          size="small"
                          fullWidth
                          id="fechaInicioPago"
                          name="fechaInicioPago"
                          label="Fecha Inicio Pago"
                          type={"date"}
                          className="mb-3"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.fechaInicioPago}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={
                            formik.touched.fechaInicioPago &&
                            Boolean(formik.errors.fechaInicioPago)
                          }
                          helperText={
                            formik.touched.fechaInicioPago &&
                            formik.errors.fechaInicioPago
                          }
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="col-md-8">
                        <TextField
                          size="small"
                          fullWidth
                          id="domicilioCobro"
                          name="domicilioCobro"
                          label="Domicilio Cobro"
                          type={"text"}
                          className="mb-3"
                          value={formik.values.domicilioCobro}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={
                            formik.touched.domicilioCobro &&
                            Boolean(formik.errors.domicilioCobro)
                          }
                          helperText={
                            formik.touched.domicilioCobro &&
                            formik.errors.domicilioCobro
                          }
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          id="tipoPrecio"
                          name="tipoPrecio"
                          label="Tipo de Precio"
                          type={"text"}
                          className="mb-3"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.tipoPrecio}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={
                            formik.touched.tipoPrecio &&
                            Boolean(formik.errors.tipoPrecio)
                          }
                          helperText={
                            formik.touched.tipoPrecio &&
                            formik.errors.tipoPrecio
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          id="diaDePago"
                          name="diaDePago"
                          label="Día de pago"
                          type={"number"}
                          className="mb-3"
                          value={formik.values.diaDePago}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={
                            formik.touched.diaDePago &&
                            Boolean(formik.errors.diaDePago)
                          }
                          helperText={
                            formik.touched.diaDePago && formik.errors.diaDePago
                          }
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col">
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            shrink={true}
                          >
                            Modo de Pago
                          </InputLabel>
                          <NativeSelect
                            value={formik.values.modoDePago}
                            onChange={formik.handleChange}
                            disabled={isDisabled}
                            defaultValue={"1"}
                            inputProps={{
                              name: "modoDePago",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value={"1"}>Semanal</option>
                            <option value={"2"}>Catorcenal</option>
                            <option value={"3"}>Quincenal</option>
                            <option value={"4"}>Mensual</option>
                          </NativeSelect>
                        </FormControl>
                      </div>

                      <div className="col">
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            shrink={true}
                          >
                            Forma de Pago
                          </InputLabel>
                          <NativeSelect
                            value={formik.values.formaPago}
                            onChange={formik.handleChange}
                            disabled={isDisabled}
                            defaultValue={"1"}
                            inputProps={{
                              name: "formaPago",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value={"1"}>Oficina</option>
                            <option value={"2"}>Cobranza</option>
                            <option value={"3"}>Tranferencia Bancaria</option>
                            <option value={"4"}>Oxxo</option>
                          </NativeSelect>
                        </FormControl>
                      </div>

                      <div className="col">
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            shrink={true}
                          >
                            Serie de Cobranza
                          </InputLabel>
                          <NativeSelect
                            value={formik.values.serieDeCobranza}
                            onChange={formik.handleChange}
                            disabled={isDisabled}
                            inputProps={{
                              name: "serieDeCobranza",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value={"A"}>A</option>
                            <option value={"AA"}>AA</option>
                            <option value={"B"}>B</option>
                            <option value={"BB"}>BB</option>
                            <option value={"D"}>D</option>
                            <option value={"E"}>E</option>
                            <option value={"F"}>F</option>
                            <option value={"G"}>G</option>
                            <option value={"H"}>H</option>
                            <option value={"I"}>I</option>
                            <option value={"J"}>J</option>
                            <option value={"P"}>P</option>
                            <option value={"MA"}>MA</option>
                            <option value={"SM"}>SM</option>
                            <option value={"S"}>S</option>
                            <option value={null}>Sin Información</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  {props.permisoParaEditar == true ? (
                    <>
                      <button
                        className="my-4 btn btn-success w-100 text-white"
                        type="submit"
                        disabled={isDisabled}
                      >
                        Guardar Finanzas
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
