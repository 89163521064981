import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { solicitarTicketParaImpresion } from "./APIImpresionTickets";

export const newContractPayment = async (user, values) => {
  let url = API + "contracts/newPayment";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      debugger;

      let armandoObjeto = {
        encabezado: {
          urlImagen: "https://login.funerariavitanova.com/logo.jpg",
          telefono: "4616120088",
          correo: "cobranza@vitanova.com",
          paginaWeb: "www.funerariavitanova.com",
        },
        detalle: {
          nombreTitular: response.data.titular,
          fechaPago: response.data.fechaPago,
          folio: response.data.serie + response.data.folio,
          contrato: response.data.serieContrato + response.data.noContrato,
          saldoAnterior: response.data.saldoAnterior,
          abono: response.data.abono,
          saldoActual: response.data.saldoActual,
          uuid: response.data.guid,
        },
      };
      
      solicitarTicketParaImpresion(user, armandoObjeto).then((resultado) => {
        debugger;
      });

      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const editContractPayment = async (user, values, idpago) => {
  let url = API + "contracts/updatePayment/" + idpago;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const deleteContractPayment = async (user, values, idpago) => {
  let url = API + "contracts/eliminarPago/" + idpago;

  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const getTopPagos = async (user) => {
  let url = API + "contracts/getTopPagos";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
export const getTopPagosMantenimiento = async (user) => {
  let url = API + "contracts/getTopPagos";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
