import { API, NuevaAPICobranza } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const addPrecioMantenimientoAPI = async (
  user,
  fechaInicio,
  fechaFin,
  idusuario
) => {
  let url =
    API +
    `pos/pagosZ/?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idusuario=${idusuario}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      let arregloUnificado = [];
      for (const pago of response.data.pagosOrdenes) {
        arregloUnificado.push(pago);
      }
      for (const pago of response.data.pagosPrevision) {
        arregloUnificado.push(pago);
      }

      arregloUnificado.sort((a, b) => b.idpago - a.idpago);

      response.data.arregloUnificado = arregloUnificado;

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const obtenerTicketsParaReimpresionAPI = async (
  user,
  fechaInicio,
  fechaFin,
  idusuario
) => {
  let url = NuevaAPICobranza + `prevision/listadoPagosTicket`;

  try {
    const response = await axios.post(
      url,
      {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
          maxBodyLength: Infinity,
        },
      }
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const obtenerReporteZ = async (
  user,
  fechaInicio,
  fechaFin,
  idusuario
) => {
  let url =
    API +
    `pos/pagosZ/?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idusuario=${idusuario}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      let arregloUnificado = [];
      for (const pago of response.data.pagosOrdenes) {
        arregloUnificado.push(pago);
      }
      for (const pago of response.data.pagosPrevision) {
        arregloUnificado.push(pago);
      }

      arregloUnificado.sort((a, b) => b.idpago - a.idpago);

      response.data.arregloUnificado = arregloUnificado;

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
