import React from "react";
import { AuthContext } from "../../auth/authContext";
import { getContratosPrevisionTop } from "../../API/ApiContratos";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DataTable from "react-data-table-component";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";

const guidVista = "11376cf7-3115-40aa-8ce3-a20e3c08668a";

const TablaUltimosContratosComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [params, setParams] = React.useState({
    order: "DESC",
    limit: 50,
    offset: 0,
  });

  const [finalData, setFinalData] = React.useState([]);
  const [finalDataSumarizado, setFinalDataSumarizado] = React.useState([]);

  const [verNumeralia, setVerNumeralia] = React.useState(false);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "35dcce75-7eca-4a32-8c85-b69286885417" &&
              permiso.allowed == 1
            ) {
              setVerNumeralia(true);
            }
          }
        }
      }
    );
  }, []);

  const columns = [
    {
      name: "Contrato",
      selector: (row) => row.serie + " - " + row.noContrato,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => CambiarFormatoFechaHelper(row.fechaContrato),
      sortable: true,
    },
    {
      name: "Precio",
      selector: (row) => {

        
        let servicio;
        if (row?.detallefinanzascontratos?.[0]?.servicio?.nombre) {
          servicio =
            "$ " +
            parseFloat(row?.detallefinanzascontratos?.[0]?.importe)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
          servicio = <strong style={{ color: "red" }}>Sin Precio</strong>;
        }
        return servicio;
      },
      sortable: true,
    },
    {
      name: "Descripción",
      selector: (row) => {
        let servicio = "";
        if (row?.detallefinanzascontratos?.[0]?.servicio?.nombre) {
          servicio = row?.detallefinanzascontratos?.[0]?.servicio?.nombre;
        } else {
          servicio = <strong style={{ color: "red" }}>Sin Servicio</strong>;
        }
        return servicio;
      },
      sortable: true,
    },
    /* 
    {
      name: "Vendedor",
      selector: (row) => {
        let nombre = "";
        let apaterno = "";
        let amaterno = "";
        if (row?.empleadocontratos?.[0]?.empleado?.nombre) {
          nombre = row?.empleadocontratos?.[0]?.empleado?.nombre;
        } else {
          return <strong style={{ color: "red" }}>Sin Vendedor</strong>;
        }

        if (row?.empleadocontratos?.[0]?.empleado?.apaterno) {
          apaterno = row?.empleadocontratos?.[0]?.empleado?.apaterno;
        }

        if (row?.empleadocontratos?.[0]?.empleado?.amaterno) {
          amaterno = row?.empleadocontratos?.[0]?.empleado?.amaterno;
        }

        return nombre + " " + apaterno + " " + amaterno;
      },

      sortable: true,
    },
    */
    {
      name: "Titular",
      selector: (row) => row.titular,
      sortable: true,
    },

    {
      name: "Estado",
      selector: (row) => {
        if (row.status == 1) {
          return <span className="badge rounded-pill bg-success">Activo</span>;
        }
        if (row.status == 2) {
          return (
            <span className="badge rounded-pill bg-secondary">Cancelado</span>
          );
        }
        if (row.status == 3) {
          return <span className="badge rounded-pill bg-light">Inactivo</span>;
        }
        if (row.status == 4) {
          return (
            <>
              <span className="badge rounded-pill bg-danger">
                <DoneAllIcon />
                Otorgado
              </span>
            </>
          );
        }
        if (row.status == 5) {
          return (
            <>
              {" "}
              <span className="badge rounded-pill bg-secondary">
                <DoneAllIcon /> Pagado
              </span>
            </>
          );
        }
        if (row.status == 6) {
          return (
            <>
              <span className="badge rounded-pill bg-secondary">
                <DoneAllIcon /> Título
              </span>
            </>
          );
        }
      },
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => {
        return (
          <>
            {" "}
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                window.open("/contrato/" + row.id + "/false", "_self");
              }}
              type="button"
            >
              Acceder a contrato
            </button>
          </>
        );
      },
      sortable: true,
    },
  ];

  React.useEffect(() => {
    getContratosPrevisionTop(user, params).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data.rows);
        setFinalDataSumarizado(resultado.data);
      } else {
        setFinalData([]);
        setFinalDataSumarizado([]);
      }
    });
  }, [params]);

  return (
    <>
      {verNumeralia == true ? (
        <>
          <div className=" container-fluid mb-2">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Total Contratos</h5>
                    <p className="card-text">{finalDataSumarizado.count}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Total Vendido</h5>
                    <p className="card-text">
                      {finalDataSumarizado.totalVendido}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      ) : null}

      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12" style={{ textAlign: "right" }}>
            <button
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                if (params.offset == 0) {
                  toast.info(
                    "No puedes establecer esto porque estas en la primera página"
                  );
                  return;
                }

                setParams((prevParams) => {
                  return {
                    ...prevParams,
                    offset:
                      params.offset - params.limit == 1
                        ? 0
                        : params.offset - params.limit,
                  };
                });
              }}
            >
              {" "}
              <KeyboardArrowLeftIcon fontSize="inherit" />
            </button>
            <button
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                setParams((prevParams) => {
                  return {
                    ...prevParams,
                    offset:
                      params.offset === 0
                        ? params.limit + 1
                        : params.limit + params.offset,
                  };
                });
              }}
            >
              {" "}
              <KeyboardArrowRightIcon fontSize="inherit" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <DataTable
              columns={columns}
              data={finalData}
              fixedHeader={true}
              fixedHeaderScrollHeight={"50vh"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TablaUltimosContratosComponent;
