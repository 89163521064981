import React, { createContext, useState, useContext } from "react";
import { LoadingComponent } from "../Components/LoadingComponent/LoadingComponent";

// Crear el contexto
const LoadingContext = createContext();

// Crear el proveedor del contexto
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {isLoading && <LoadingComponent />}
      {children}
    </LoadingContext.Provider>
  );
};

// Hook para usar el contexto
export const useLoading = () => {
  return useContext(LoadingContext);
};
