import React from "react";
import { BuscadorDeOrdenesDeServicioComponent } from "./../BuscadorDeOrdenesDeServicioComponent/BuscadorDeOrdenesDeServicioComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "./../BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import * as yup from "yup";
import { toast } from "react-toastify";
import { AuthContext } from "./../../auth/authContext";
import { imprimirTicket } from "./../../API/ApiImpresoraTickets";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { solicitarTicketParaImpresionOrdenServicio } from "../../API/APIImpresionTickets";
import { useLoading } from "../../contexts/LoadingContext";

const validationSchema = yup.object({
  noRecibo: yup
    .string("Ingresa Número Recibo")
    .required("Número Recibo requerido"),
  folio: yup.string("Ingresa Folio").required("Folio requerido"),
  fechaPago: yup.string("Ingresa Fecha Pago").required("Precio requerido"),
  abono1: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .required("Monto requerido")
    .min(1, "Solo números positivos"),
  abonoIva: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .min(0, "Solo números positivos"),
  recibio: yup.string("Ingresa Recibió").required("Recibió requerido"),
  idtipoPago: yup.string("Ingresa monto").required("Monto requerido"),
});

const AddPaymentOrdenServicioComponent = () => {
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");

  const { isLoading, startLoading, stopLoading } = useLoading();

  const { user, dispatch } = React.useContext(AuthContext);

  var decoded = jwt_decode(user.accessToken);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      noRecibo: "0",
      folio: numeroOrdenServicio,
      fechaPago: DateTime.now().toISODate(),
      abono1: "",
      idtipoPago: "1",
      letra: "C",
      abonoIva: "0",
      recibio: decoded.nombre,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      let fechaParaGuardar = DateTime.fromISO(values.fechaPago);

      let fechaActual = DateTime.now();

      if (fechaParaGuardar <= fechaActual) {
        setIsDisabled(true);
      } else {
        toast.info("Verifica la fecha de pago");
        toast.error(
          "No puedes ingresar fechas de pago posteriores a la fecha actual"
        );

        return;
      }

      startLoading();

      setIsDisabled(true);

      nuevoPagoOrdenServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Pago registrado con éxito");
          stopLoading();
          setIsDisabled(false);
        } else {
          stopLoading();
          setIsDisabled(false);
        }
      });
    },
  });

  React.useEffect(() => {
    formik.setFieldValue("folio", numeroOrdenServicio);
  }, [numeroOrdenServicio]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BuscadorDeOrdenesDeServicioHookComponent
              setNumeroOrdenServicio={setNumeroOrdenServicio}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <hr />
          </div>
        </div>
        <div className="row">
          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row row-cols-1">
              <div className="col-6">
                <TextField
                  size="small"
                  fullWidth
                  id="letra"
                  name="letra"
                  label="Letra"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.letra}
                  onChange={formik.handleChange}
                  variant="standard"
                  select
                  error={formik.touched.letra && Boolean(formik.errors.letra)}
                  helperText={formik.touched.letra && formik.errors.letra}
                  disabled={isDisabled}
                >
                  <MenuItem value={"C"}>C</MenuItem>
                  <MenuItem value={"SM"}>SM</MenuItem>
                  <MenuItem value={"P"}>P</MenuItem>
                </TextField>
              </div>

              <div className="col-6">
                <TextField
                  size="small"
                  fullWidth
                  id="noRecibo"
                  name="noRecibo"
                  label="No. Recibo"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.noRecibo}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.noRecibo && Boolean(formik.errors.noRecibo)
                  }
                  helperText={formik.touched.noRecibo && formik.errors.noRecibo}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="folio"
                  name="folio"
                  label="Folio"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.folio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.folio && Boolean(formik.errors.folio)}
                  helperText={formik.touched.folio && formik.errors.folio}
                  disabled={true}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaPago"
                  name="fechaPago"
                  label="Fecha Pago"
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaPago}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaPago && Boolean(formik.errors.fechaPago)
                  }
                  helperText={
                    formik.touched.fechaPago && formik.errors.fechaPago
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="abono1"
                  name="abono1"
                  label="Abono"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.abono1}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { min: 0, step: "any" },
                  }}
                  variant="standard"
                  error={formik.touched.abono1 && Boolean(formik.errors.abono1)}
                  helperText={formik.touched.abono1 && formik.errors.abono1}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="abonoIva"
                  name="abonoIva"
                  label="Abono IVA"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.abonoIva}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { min: 0, step: "any" },
                  }}
                  variant="standard"
                  error={
                    formik.touched.abonoIva && Boolean(formik.errors.abonoIva)
                  }
                  helperText={formik.touched.abonoIva && formik.errors.abonoIva}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="recibio"
                  name="recibio"
                  label="Recibió"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.recibio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.recibio && Boolean(formik.errors.recibio)
                  }
                  helperText={formik.touched.recibio && formik.errors.recibio}
                  disabled={isDisabled}
                />
              </div>

              <div className="col">
                <FormControl fullWidth variant="standard">
                  <InputLabel id="idtipoPago" shrink={true}>
                    Tipo de Pago
                  </InputLabel>
                  <Select
                    labelId="idtipoPago"
                    id="idtipoPago"
                    value={formik.values.idtipoPago}
                    label="Tipo de Pago"
                    onChange={(e) => {
                      formik.setFieldValue("idtipoPago", e.target.value);
                    }}
                  >
                    <MenuItem value={1}>Efectivo</MenuItem>
                    <MenuItem value={2}>Tarjeta</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <br />
              <br />
              <div className="col mt-2">
                <button
                  type="submit"
                  className="btn btn-sm btn-success w-100"
                  disabled={isDisabled}
                >
                  Agregar pago a orden de servicio: {formik.values.letra}{" "}
                  {formik.values.noRecibo}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPaymentOrdenServicioComponent;
